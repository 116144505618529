import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Page from "./page";

const query = graphql`
	query IndexQuery {
		site {
			siteMetadata {
				title
			}
		}

		header: file(relativePath: { eq: "header/header.png" }) {
			...ImageData
		}

		hero: file(relativePath: { eq: "seattle-trees.jpg" }) {
			...ImageData
		}
	}

	fragment ImageData on File {
		childImageSharp {
			gatsbyImageData(layout: FULL_WIDTH)
		}
	}
`;

const BlogIndex = (props) => (
	<StaticQuery
		query={query}
		render={(data) => {
			return <Page {...props} data={data} />;
		}}
	/>
);
export default BlogIndex;
