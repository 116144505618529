import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import InsetContent from "../InsetContent";
import EntryHeader from "../Headers/Entry";
import LargeIcon from "../MeIcon/large";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faTwitter from "@fortawesome/fontawesome-free-brands/faTwitter";
import faMastodon from "@fortawesome/fontawesome-free-brands/faMastodon";
import faGit from "@fortawesome/fontawesome-free-brands/faGithub";
import faYouTube from "@fortawesome/fontawesome-free-brands/faYoutube";
import faRSS from "@fortawesome/fontawesome-free-solid/faRss";
import faKey from "@fortawesome/fontawesome-free-solid/faKey";
import faPlus from "@fortawesome/fontawesome-free-solid/faPlus";
import faEnvelope from "@fortawesome/fontawesome-free-solid/faEnvelope";

import Sections from "./Sections";

const Content = styled(InsetContent)`
	padding: 4em 0 0 0;
	.gatsby-image-outer-wrapper {
		text-align: center;
	}

	a {
		text-decoration: none;
	}

	h1 {
		display: block;
		text-align: center;
		color: white;
		text-shadow: 0px 2px 8px rgba(0, 0, 0, 1);
		font-size: ${(props) =>
			props.textSize === "small" ? "2rem" : "2.5rem"};
		margin-top: 0;

		@media only screen and (max-width: 450px) {
			font-size: ${(props) =>
				props.textSize === "small" ? "1.5rem" : "2rem"};
		}
	}

	h4 {
		display: block;
		text-align: center;
		color: white;
		font-size: 1.2rem;
		margin-bottom: 2em;
		margin-top: 0;
		text-shadow: 0px 2px 8px rgba(0, 0, 0, 1);
		@media only screen and (max-width: 450px) {
			font-size: 1.1rem;
			margin-bottom: 1em;
		}
		line-height: 1.5em;
	}

	p {
		display: block;
		text-align: center;
		color: white;
	}
`;

const NavLinks = styled.nav`
	display: inline-block;
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

const StyledA = styled.a`
	margin: 20px;
	@media only screen and (max-width: 450px) {
		margin: 10px;
		font-size: 1.3rem;
	}
	@media only screen and (max-width: 280px) {
		margin: 4px;
	}
	text-decoration: none;
	background: none;
	text-shadow: none;
	color: white;
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
	font-size: 2rem;
	line-height: 1em;

	svg {
		height: 1em;
		width: 1em;
		filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5));
	}
`;

const StyledLink = styled(Link)`
	margin: 20px;
	@media only screen and (max-width: 450px) {
		margin: 10px;
		font-size: 1.3rem;
	}
	@media only screen and (max-width: 280px) {
		margin: 6px;
	}

	text-decoration: none;
	background: none;
	text-shadow: none;
	color: white;
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
	line-height: 1em;
	font-size: 2rem;

	svg {
		height: 1em;
		width: 1em;
		filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.5));
	}
`;

const Buttons = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 2em;

	@media only screen and (max-width: 450px) {
		flex-direction: column;
	}
	display: none;
`;

const Button = styled.button`
	margin: 0px 12px;
	background: rgba(0, 0, 0, 0.31);
	border: 2px solid white;
	border-radius: 4px;
	color: white;
	padding: 5px 10px 5px 10px;
	text-shadow: 0px 2px 8px rgba(0, 0, 0, 1);

	@media only screen and (max-width: 450px) {
		margin: 6px;
	}
`;

const ImageCenterer = styled.div`
	position: relative;
	text-align: center;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
	margin-right: 10px;
	text-shadow: 0px 2px 8px rgba(0, 0, 0, 1);
`;

const Hero = ({ photo, header }) => (
	<div>
		<EntryHeader isRoot={true} height="32em" noMargin={true} image={header}>
			<Content className="h-card" width={600}>
				<Link to="/" className="u-url">
					<ImageCenterer>
						<LargeIcon />
					</ImageCenterer>
					<h1 className="p-name">Steve Streza</h1>
				</Link>
				<h4 className="p-note">
					Software developer, comic book writer, video creator,
					hobbyist located in Seattle, WA.
				</h4>
				<Buttons>
					<Button>
						<ButtonIcon icon={faPlus} />
						Follow
					</Button>
					<Button>
						<ButtonIcon icon={faEnvelope} />
						Message
					</Button>
				</Buttons>
				<NavLinks>
					<StyledA
						rel="me"
						href={"https://indieweb.social/@stevestreza"}
					>
						<FontAwesomeIcon icon={faMastodon} />
					</StyledA>
					<StyledA rel="me" href={"https://github.com/SteveStreza"}>
						<FontAwesomeIcon icon={faGit} />
					</StyledA>
					<StyledA
						rel="me"
						href={
							"https://www.youtube.com/channel/UCgvP1uuOjs2ZkCNIPjK1kAg"
						}
					>
						<FontAwesomeIcon icon={faYouTube} />
					</StyledA>
					<StyledA
						alt="PGP Key"
						rel="pgpkey"
						href={"/stevestreza.pub"}
					>
						<FontAwesomeIcon icon={faKey} />
					</StyledA>
					<StyledA href={"/feed.xml"}>
						<FontAwesomeIcon icon={faRSS} />
					</StyledA>
				</NavLinks>
			</Content>
		</EntryHeader>
		<Sections />
	</div>
);
export default Hero;
