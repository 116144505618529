import React from "react";
import InStreamElement from "../InStreamElement";

// export default () => (
//     <InStreamElement>
//             Thanks for stopping by my personal site! If you like
//             what you see, please consider following via a feed
//             reader, a social network, or email subscription.
//     </InStreamElement>
// )

const IgnoredFollow = () => <div />;
export default IgnoredFollow;
