import React from "react";
import { Link } from "gatsby";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import Layout from "../../components/layout";
import SiteNav from "../../components/SiteNav";
import SiteFooter from "../../components/SiteFooter";

import PostFeaturedImage from "../../components/PostFeaturedImage";
import Video from "../../components/Video";
import ReadMoreHTML from "../../components/ReadMoreHTML";
import EntryHeader from "../../components/Headers/Entry";
import HeroHeader from "../../components/Headers/Hero";
import ContentTheme from "../../components/ContentTheme";
import InsetContent from "../../components/InsetContent";
import { TagList, Tag } from "../../components/TagList";
import Sections from "../../components/Headers/Sections";
import FollowCTA from "../../components/CTA/Follow";
import FullBleed from "../../components/FullBleed";
import InStreamElement from "../../components/InStreamElement";
import Author from "../../components/Author";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faNewer from "@fortawesome/fontawesome-free-solid/faFastForward";
import faOlder from "@fortawesome/fontawesome-free-solid/faFastBackward";

const HeaderImage = styled(Img)`
	height: 7em;
`;

const Article = styled.article`
	background: ${(props) => props.theme.contentBackground};
	border-top: ${(props) => props.theme.contentTopBorder};
	border-bottom: ${(props) => props.theme.contentBottomBorder};
	padding-top: ${(props) => (props.image ? "0" : "2em")};
	padding-bottom: 2em;
`;

const PostContent = styled(ReadMoreHTML)`
	color: ${(props) => props.theme.contentColor};
`;

const PaginationContainer = styled.div`
	background: #efebf4;
	border-top: 1px solid #d8cee5;
`;

const Pagination = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 2em;
	padding-bottom: 2em;
	font-weight: bold;
	a {
		text-decoration: none;
	}
`;

const PaginationIcon = styled(FontAwesomeIcon)`
	height: 0.75em;
	top: -0.125em;
	position: relative;
	margin-left: 4px;
	margin-right: 4px;
`;

const PhotoImg = styled(FullBleed)`
	margin-bottom: 2em;
	margin-top: 2em;
	box-shadow: ${(props) => props.theme.contentBoxShadow || "none"};
`;

export default class BlogIndex extends React.Component {
	render() {
		const page = get(this, "props.pageContext.page");
		const isRoot =
			(page || 0) === 1 &&
			get(this, "props.pageContext.preventRoot") !== true;
		const siteTitle = get(this, "props.data.site.siteMetadata.title");
		const siteDescription = get(
			this,
			"props.data.site.siteMetadata.description"
		);
		const posts = get(this, "props.pageContext.nodes");
		const image = get(this, "props.data.header");
		const { next, prev } = get(this, "props.pageContext");
		const permalink = this.props.location.pathname;
		return (
			<Layout>
				<div>
					<Helmet>
						<link
							rel="alternate"
							type="application/rss+xml"
							title="Steve Streza"
							href="https://stevestreza.com/feed.xml"
						/>
					</Helmet>
					{permalink && (
						<Helmet key="permalink">
							<link
								rel="self"
								href={"https://stevestreza.com" + permalink}
							/>
						</Helmet>
					)}
					{isRoot && (
						<Helmet>
							<link
								rel="authorization_endpoint"
								href="https://indieauth.com/auth"
							/>
							<link
								rel="token_endpoint"
								href="https://tokens.indieauth.com/token"
							/>
							<link
								rel="micropub"
								href="https://webmaster.stevestreza.com/micropub"
							/>
							<link
								rel="microsub"
								href="https://aperture.p3k.io/microsub/40"
							/>
						</Helmet>
					)}
					{isRoot && (
						<HeroHeader
							header={get(
								this,
								"props.data.hero.childImageSharp.gatsbyImageData"
							)}
						/>
					)}
					{!isRoot && <SiteNav />}
					{!isRoot && image && (
						<PostFeaturedImage
							height={"7em"}
							image={image.childImageSharp.gatsbyImageData}
						/>
					)}
					{!isRoot && <Sections />}
					<Helmet title={siteTitle} />
					{isRoot && <FollowCTA />}
					<div className="h-feed">
						{posts.map(({ node }) => {
							const title = get(node, "frontmatter.title");
							const image = get(
								node,
								"frontmatter.image.childImageSharp.gatsbyImageData"
							);
							return (
								<ContentTheme
									key={node.id}
									layout={node.frontmatter.layout}
								>
									<Article
										layout={
											node.frontmatter.layout || "post"
										}
										className={
											"h-entry " +
											"layout-" +
											(node.frontmatter.layout || "post")
										}
										key={node.fields.slug}
										image={image}
									>
										{node.frontmatter.layout === "photo" &&
											node.frontmatter.image && (
												<InsetContent>
													<PhotoImg>
														<Img image={image} />
													</PhotoImg>
												</InsetContent>
											)}

										<EntryHeader
											slug={node.fields.slug}
											title={title}
											date={node.frontmatter.date}
											dateISO8601={
												node.frontmatter.dateISO8601
											}
											link={node.frontmatter.link}
											image={
												node.frontmatter.layout !==
												"photo"
													? image
													: null
											}
										/>
										<InStreamElement display={false}>
											<Author
												inStream={true}
												name={siteTitle}
												bio={siteDescription}
											/>
										</InStreamElement>
										<InsetContent>
											<span className="e-content">
												{node.frontmatter.video_url && (
													<section>
														<Video
															url={
																node.frontmatter
																	.video_url
															}
														/>
													</section>
												)}
												<PostContent
													html={node.html}
													slug={node.fields.slug}
													link={node.frontmatter.link}
													truncate={true}
												/>
											</span>
											{node.frontmatter.tags && (
												<TagList>
													{node.frontmatter.tags.map(
														(tag) => (
															<Tag
																key={tag}
																tag={tag}
															/>
														)
													)}
												</TagList>
											)}
										</InsetContent>
									</Article>
								</ContentTheme>
							);
						})}
						<PaginationContainer>
							<InsetContent width={900}>
								<Pagination>
									{next && (
										<Link rel="next" to={next}>
											<PaginationIcon icon={faOlder} />
											{" Older Posts"}
										</Link>
									)}
									{prev && (
										<Link rel="prev" to={prev}>
											{"Newer Posts "}
											<PaginationIcon icon={faNewer} />
										</Link>
									)}
								</Pagination>
							</InsetContent>
						</PaginationContainer>
					</div>
					<SiteFooter />
				</div>
			</Layout>
		);
	}
}
