import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import SmallIcon from "./MeIcon/small";

const CardRoot = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	margin-top: ${(props) => (props.hasMargin ? "2em" : "0")};

	& > * {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
`;

const Icon = styled(SmallIcon)`
	border-radius: 30pt;
	box-shadow: ${(props) =>
		props.hasBoxShadow ? "0px 1px 5px rgba(0, 0, 0, 0.4)" : ""};
	img {
		border-radius: 30pt;
	}
	margin-right: 10px;
`;

const Text = styled.div`
	padding-left: 8px;

	p {
		margin: 0;
		padding: 0;
		font-size: 11pt;
		font-weight: normal;
	}

	.u-author {
		font-weight: bold;
		text-decoration: none;
	}
`;

const Author = ({ name, bio, inStream = false, ...props }) => (
	<CardRoot {...props} className="p-author h-card" hasMargin={!inStream}>
		<Link to="/" rel="me">
			<Icon hasBoxShadow={!inStream} />
		</Link>
		<Text>
			<p>
				Written by{" "}
				<Link to="/" rel="me" className="p-name u-author">
					{name}
				</Link>
			</p>
			<p>{bio}</p>
		</Text>
	</CardRoot>
);
export default Author;
