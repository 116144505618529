import React from "react";
import styled from "styled-components";
import InsetContent from "./InsetContent";

const Container = styled.aside`
	border: 1px solid #d8cee5;
	background-color: #efebf4;
	color: #27164c;
	padding: 10px;
	border-radius: 8px;
	margin-top: 2em;
	margin-left: -10px;
	margin-right: -10px;
	font-size: smaller;
`;

const InStreamElement = ({ display, children }) => {
	if (!children) {
		return [];
	}

	const props = {};
	if (display !== undefined && !display) {
		props.style = { display: "none" };
	}

	return (
		<InsetContent {...props}>
			<Container>{children}</Container>
		</InsetContent>
	);
};
export default InStreamElement;
